import jwt_decode from 'jwt-decode';

const localStorageKey = 'livecallAccessToken';

function getTokenExp(token) {
    const payload = jwt_decode(token);
    return new Date(payload.exp * 1000);
}

function assertTokenIsValid(token) {
    const exp = getTokenExp(token);

    if (exp < new Date()) {
        throw new TokenExpiredError(token, exp);
    }
}

class TokenExpiredError extends Error {
    constructor(token, exp) {
        super(`Token ${token.substr(0, 10)}... has expired ${exp}`);

        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, TokenExpiredError);
        }

        this.name = 'TokenExpiredError';
        this.token = token;
        this.exp = exp;
    }
}

export default class API {
    token = undefined;
    base = process.env.VUE_APP_API_BASE;

    _expTimeout = null;

    constructor(token) {
        this.setToken(token);

        if (!this.token) {
            try {
                this.setToken(window.localStorage.getItem(localStorageKey));
            } catch (e) {
                console.log(`Found local storage token, but: ${e}`);
            }
        }
    }

    setToken(token) {
        if (!token) {
            return this.token = null;
        }

        assertTokenIsValid(token);
        this.token = token;
        window.localStorage.setItem(localStorageKey, token);
        this._setExpTimeout();
    }

    _setExpTimeout() {
        if (this._expTimeout) {
            clearTimeout(this._expTimeout);
            this._expTimeout = null;
        }

        const timeout = getTokenExp(this.token) - new Date();
        console.log(`Token ${this.token.substr(0, 10)}... will expire in ${timeout} ms`);
        this._expTimeout = setTimeout(this.unsetToken.bind(this), timeout);
    }

    unsetToken() {
        this.token = null;
        window.localStorage.removeItem(localStorageKey);
    }

    get isAuthenticated() {
        return !!this.token;
    }

    get identity() {
        try {
            return jwt_decode(this.token).identity;
        } catch (e) {
            return undefined;
        }
    }

    /**
     * @return {Promise<Response>}
     */
    fetch(resource, init = {}) {
        if (this.token) {
            init.headers = init.headers || {};
            init.headers.Authorization = init.headers.Authorization || `Bearer ${this.token}`;
        }

        return fetch(`${this.base}${resource}`, init);
    }

    // noinspection JSUnusedGlobalSymbols
    static install(Vue, options = {}) {
        // noinspection JSUnusedGlobalSymbols
        Vue.prototype.$api = Vue.observable(new API(options.token));
    }
}
