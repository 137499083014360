<template>
  <v-app dark>
    <v-app-bar elevate-on-scroll app dark color="transparent">
      <v-toolbar-title>
        <img class="logo mr-2" alt="LiveCall" src="./assets/logo.white.text.png"> for LINE WORKS
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <logged-in-widget></logged-in-widget>

      <locale-switch-widget></locale-switch-widget>
    </v-app-bar>
    <v-main>
      <v-container>
        <router-view/>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  components: {
    LoggedInWidget: () => import('@/components/LoggedInWidget'),
    LocaleSwitchWidget: () => import('@/components/LocaleSwitchWidget')
  }
}
</script>

<!--suppress CssUnusedSymbol -->
<style scoped>
  .v-toolbar__title .logo {
    height: 36px;
    vertical-align: bottom;
  }

  @media screen and (max-width: 599px) {
    .v-toolbar__title {
      font-size: .8rem;
    }

    .v-toolbar__title .logo {
      height: 24px;
    }
  }

  .v-application.theme--light {
    background: url(./assets/blurred-photo-backdrop.jpg) no-repeat;
    background-size: cover;
  }

  .theme--dark.v-app-bar.v-toolbar.v-sheet.v-app-bar--is-scrolled {
    background-color: #034661 !important;
  }
</style>