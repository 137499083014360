import Vue from 'vue'
import VueGtm from 'vue-gtm';
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'
import API from './api';
import i18n from './i18n'

Vue.use(API);

Vue.config.productionTip = false

if (process.env.VUE_APP_GTM) {
  Vue.use(VueGtm, {
    id: process.env.VUE_APP_GTM,
    defer: true,
    vueRouter: router
  })
}

new Vue({
  vuetify,
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
