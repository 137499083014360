import '@formatjs/intl-numberformat/polyfill';
import '@formatjs/intl-numberformat/locale-data/en';
import '@formatjs/intl-numberformat/locale-data/ja';
import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n)

function loadLocaleMessages () {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

const localStorageKey = 'locale';

export function saveLocale(locale) {
  window.localStorage.setItem(localStorageKey, locale);
}

function restoreLocale() {
  return window.localStorage.getItem(localStorageKey);
}

const locales = ['en', 'ja'];
const defaultLocale = process.env.VUE_APP_I18N_LOCALE || 'ja';
const detectedLocale = locales.find(l => new RegExp(`^${l}\\b`).test(navigator.language));
const restoredLocale = restoreLocale();
const locale = restoredLocale || detectedLocale || defaultLocale;

if (locale !== defaultLocale && !restoredLocale) {
  saveLocale(locale);
}

const numberFormats = {
  en: {
    currency: {
      style: 'currency',
      currency: 'JPY'
    },
    minutes: {
      style: 'unit',
      unit: 'minute',
      notation: 'compact',
      maximumFractionDigits: 1
    }
  },
  ja: {
    currency: {
      style: 'currency',
      currency: 'JPY',
      currencyDisplay: 'symbol'
    },
    minutes: {
      style: 'unit',
      unit: 'minute',
      notation: 'compact',
      maximumFractionDigits: 1
    }
  }
};

export default new VueI18n({
  locale: locale,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages(),
  numberFormats
});
