import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'Login',
        component: () => import('@/views/Login'),
        props: route => ({
            samlToken: route.query.token,
            error: route.query.error
        })
    },
    {
        path: '/setup/:step',
        name: 'Setup',
        component: () => import('@/views/Setup'),
        props: route => ({
            initialStep: +route.params.step,
            samlToken: route.query.token,
            fail: 'fail' in route.query
        })
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: () => import('@/views/Dashboard')
    },
    {
        path: '/about',
        name: 'About',
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    }
];

const router = new VueRouter({ mode: 'history', routes });

export default router
